import { useState, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import Draggable from 'react-draggable';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Card,
  Typography,
  Button,
  CardMedia,
  Avatar,
  Stack,
  Slide,
  IconButton,
  Grid
} from '@material-ui/core';
import CardHeader from '@material-ui/core/Card';
import LoadingSkeleton from './LoadingSkeleton';

const avatarStyle = {
  position: 'fixed',
  zIndex: '99999',
  bottom: '20px',
  right: '20px',
  width: 70,
  height: 70,
  '&:hover': { backgroundColor: 'primary.main' }
};

const cardStyle = {
  paddingLeft: 0,
  paddingRight: 0,
  position: 'fixed',
  zIndex: '99999',
  right: '20px',
  height: '680px',
  width: '450px',
  maxHeight: '90vh',
  bottom: '20px'
};

export default function HelpSystem() {
  const [currentDocument, setCurrentDocument] = useState('');
  const [searchParams] = useSearchParams();
  const [currX, setCurrX] = useState(0);
  const [currWindowWidth, setCurrWindowWidth] = useState(window.innerWidth);
  const [collapsed, setCollapsed] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const location = useLocation();

  const handleWindowResize = () => {
    setCurrX(0);
    setCurrWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    const setHelpPage = () => {
      // Set current document based on page the user is on
      if (location.search !== ' ') {
        if (searchParams.get('ssh_open')) {
          setCurrentDocument('#connect_to_vrack.htm');
        } else if (searchParams.get('new_component')) {
          setCurrentDocument('#add_new_component.htm');
        } else if (searchParams.get('loading_open')) {
          setCurrentDocument('#load_vrack.htm');
        } else if (searchParams.get('step')) {
          setCurrentDocument(`#${searchParams.get('step')}.htm`);
        } else if (searchParams.get('flight_data_open')) {
          setCurrentDocument('#flight_data.htm');
        } else if (location.pathname) {
          if (location.pathname.includes('/racks/details')) {
            setCurrentDocument('#vrack_details.htm');
          } else if (location.pathname.includes('/racks')) {
            setCurrentDocument('#virtual_rack_home.htm');
          } else if (location.pathname.includes('/team/upload')) {
            setCurrentDocument('#loadables.htm');
          }
        }
      }
    };

    if (location !== null) {
      setHelpPage();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [location]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <Stack direction="row" justifyContent="flex-end" alignItems="flex-end">
      {collapsed && (
        <Avatar
          data-testid="help-button"
          component={Button}
          onClick={() => {
            setCollapsed(false);
            setLoaded(false);
          }}
          sx={avatarStyle}
        >
          <QuestionMarkIcon sx={{ fontSize: 35 }} />
        </Avatar>
      )}
      <Draggable
        onStop={(e, data) => {
          setCurrX(data.lastX);
        }}
        position={{ x: currX, y: 0 }}
        axis="x"
        bounds={{ left: -(currWindowWidth - parseInt(cardStyle.width, 10) - 20), right: 0 }}
      >
        <Slide direction="up" in={!collapsed} mountOnEnter unmountOnExit>
          <Card sx={cardStyle}>
            <Grid container direction="column" sx={{ height: '100%' }}>
              <Grid item>
                <CardHeader
                  sx={{
                    backgroundColor: `rgba(242, 242, 242)`,
                    padding: 1
                  }}
                >
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography sx={{ paddingLeft: 1 }} variant="h6">
                      Virtual Rack Help
                    </Typography>
                    <Stack direction="row" alignItems="center">
                      <IconButton
                        onClick={() => {
                          window.open(
                            `/static/help-system/vrack online help.htm${currentDocument}`
                          );
                        }}
                        aria-label="maximize"
                      >
                        <OpenInNewIcon />
                      </IconButton>

                      <IconButton
                        onClick={() => {
                          setCollapsed(true);
                          setCurrX(0);
                        }}
                        aria-label="minimize"
                      >
                        <RemoveIcon />
                      </IconButton>
                    </Stack>
                  </Stack>
                </CardHeader>
              </Grid>

              <Grid item xs sx={{ overflow: 'auto' }}>
                {!loaded && <LoadingSkeleton />}
                <CardMedia
                  component="iframe"
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: !loaded ? 'none' : ''
                  }}
                  onLoad={() => {
                    setLoaded(true);
                  }}
                  image={`/static/help-system/vrack online help.htm${currentDocument}`}
                />
              </Grid>
            </Grid>
          </Card>
        </Slide>
      </Draggable>
    </Stack>
  );
}
