import { useState } from 'react';
import {
  Modal,
  Fade,
  Backdrop,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Box,
  Button,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  InputLabel,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getAddedLRUs } from './LRU';
import { useIsFeatureFlagEnabled } from '../../../utils/FeatureFlags';
import BetaChip from '../../common/BetaChip';
import { LRUSize, diskSizes } from './LRUSize';
import PersistData from './PersistData';
import { LRUOptions } from './LRUOptions';

export const LRU_SELECT_TEST_ID = 'lru-select';
export const NEW_LRU_BUTTON_TEST_ID = 'new-lru-modal-add-lru';
export const LRU_SELECT_LABEL = 'Select an LRU';
export const NEW_LRU_MODAL_TITLE = 'Add New LRUs';

export const lruLabels = {
  ah: 'AH (Type: 372, Subtype: 6)',
  is: 'IS (Type: 372 Subtype: 1)',
  next_is: 'NEXT IS (Type: 372, Subtype: 3)',
  ex3_nc: 'eX3 NC (Type: 370 Subtype: 1)',
  next_nc: 'NEXT NC (Type: 370 Subtype: 2)',
  next_cs4: 'NEXT CS4 (Type: 336 Subtype: 3)',
  fs05: 'FS05 (Type: 193 Subtype: 5)',
  cs4: 'CS4 (Type: 336 Subtype: 2)',
  bc03: 'BC03 (Type: 340 Subtype: 4)',
  smartmonitor: 'NEXT Smart Monitor (Type: 196)'
};

const possibleLRUCombinations = [
  { fs05: { units: ['0'], system_types: ['eXW'] }, smartmonitor: {} },
  {
    bc03: {},
    fs05: { units: ['8'], system_types: ['eXCs'] },
    smartmonitor: {}
  },
  { is: {}, next_is: {}, next_cs4: {}, cs4: {}, fs05: {}, smartmonitor: {} },
  { ex3_nc: {}, next_nc: {}, next_cs4: {}, cs4: {}, fs05: {}, smartmonitor: {} },
  { ah: {}, next_is: {}, bc03: { system_types: ['integrated'] } }
];

const defaultLrus = {
  ah: {
    value: 'ah',
    label: lruLabels.ah,
    beta: true,
    enabled: false,
    units: { 0: true },
    cpu: 2000,
    memory: 2000,
    persistData: 'true',
    size: diskSizes[0],
    diskSize: diskSizes[0]
  },
  is: {
    value: 'is',
    label: lruLabels.is,
    beta: false,
    enabled: false,
    units: { 0: true, 1: false },
    cpu: 2000,
    memory: 2000,
    persistData: 'true',
    size: diskSizes[0],
    diskSize: diskSizes[0]
  },
  next_is: {
    value: 'next_is',
    label: lruLabels.next_is,
    beta: false,
    enabled: false,
    units: { 0: true, 1: false },
    cpu: 2000,
    memory: 2000,
    persistData: 'true',
    size: diskSizes[0],
    diskSize: diskSizes[0]
  },
  ex3_nc: {
    value: 'ex3_nc',
    label: lruLabels.ex3_nc,
    beta: false,
    enabled: false,
    units: { 0: true, 1: false },
    cpu: 2000,
    memory: 2000,
    persistData: 'true',
    size: diskSizes[0],
    diskSize: diskSizes[0]
  },
  next_nc: {
    value: 'next_nc',
    label: lruLabels.next_nc,
    beta: false,
    enabled: false,
    units: { 0: true, 1: false },
    cpu: 2000,
    memory: 2000,
    persistData: 'true',
    size: diskSizes[0],
    diskSize: diskSizes[0]
  },
  next_cs4: {
    value: 'next_cs4',
    label: lruLabels.next_cs4,
    beta: false,
    enabled: false,
    units: { 0: true, 1: false, 2: false, 3: false },
    cpu: 2000,
    memory: 2000,
    persistData: 'true',
    size: diskSizes[0],
    diskSize: diskSizes[0]
  },
  fs05: {
    value: 'fs05',
    label: lruLabels.fs05,
    beta: false,
    enabled: false,
    units: { 0: false, 1: false, 2: false, 8: false },
    cpu: 2000,
    memory: 2000,
    persistData: 'true',
    system_type: 'eXW',
    size: diskSizes[0],
    diskSize: diskSizes[0]
  },
  cs4: {
    value: 'cs4',
    label: lruLabels.cs4,
    beta: false,
    enabled: false,
    units: { 0: true, 1: false, 2: false, 3: false },
    cpu: 2000,
    memory: 2000,
    persistData: 'true',
    size: diskSizes[0],
    diskSize: diskSizes[0]
  },
  smartmonitor: {
    value: 'smartmonitor',
    label: lruLabels.smartmonitor,
    beta: true,
    enabled: false,
    cpu: 4000,
    memory: 4000,
    persistData: 'true',
    config: {},
    size: diskSizes[0],
    diskSize: diskSizes[0]
  },
  bc03: {
    value: 'bc03',
    label: lruLabels.bc03,
    beta: false,
    enabled: false,
    cpu: 2000,
    memory: 2000,
    persistData: 'true',
    size: diskSizes[0],
    diskSize: diskSizes[0],
    system_type: 'standalone'
  }
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  p: 4
};

// clears the units from the lru
function clearUnits(lru) {
  // clears the units of a given lru
  Object.keys(lru.units).forEach((unit) => {
    lru.units[unit] = false;
  });
}

// checks if we can add the currently selected LRU
function isAddLRUDisabled(currentLRU, addedLRUs) {
  const excludedLRUS = ['bc03', 'smartmonitor'];
  // check the following conditions and if true disable the ability to add LRU
  // 1. are there no added LRUs and is the current LRU selected a smartmonitor
  // 2. are there no units selected for the current LRU (assuming the LRU type has units)
  return (
    !currentLRU ||
    (addedLRUs.length === 0 && currentLRU.value === 'smartmonitor') ||
    (!Object.values(currentLRU.units).some(Boolean) && !excludedLRUS.includes(currentLRU.value))
  );
}

function parseUnitsFromRack(type) {
  // parses units from rack object to the format of units for this component
  const units = { ...defaultLrus[type].units };
  return units;
}

function parseResourceFromRack(lru, resourceType, rackResources) {
  // parses resource limits from rack object to the format of resource limits for this component
  if (rackResources) {
    const splitChar = resourceType === 'memory' ? 'M' : 'm';
    return parseInt(rackResources.limits[resourceType].split(splitChar)[0], 10);
  }
  return defaultLrus[lru][resourceType];
}

function getAvailableLRUs(
  lrus,
  addedLRUS,
  bc03Enabled,
  ex3ncEnabled,
  nextisEnabled,
  vsmEnabled,
  ahEnabled
) {
  // get available lrus for LRU Type dropdown
  const availableLRUs = new Set();

  // check every combination of lrus and see if the added lrus are allowed in the combination
  possibleLRUCombinations.forEach((combination) => {
    if (
      addedLRUS.every((lru) => {
        // check if the lru is in the combination
        if (!(lru in combination)) {
          return false;
        }
        // check if the lru's units are allowed in the combination
        if ('units' in combination[lru]) {
          // get the enabled units of the current lru
          const enabledUnits = Object.keys(lrus[lru].units).filter((u) => lrus[lru].units[u]);
          if (!enabledUnits.every((unit) => combination[lru].units.includes(unit))) {
            return false;
          }
        }
        // check if the lru's system type is allowed in the combination
        if ('system_types' in combination[lru]) {
          if (!combination[lru].system_types.includes(lrus[lru].system_type)) {
            return false;
          }
        }
        return true;
      })
    ) {
      // add all of the lrus of the combination to the available LRUs set if it wasn't in the addedLRUs already
      Object.keys(combination).forEach((lru) => {
        if (!addedLRUS.includes(lru)) {
          availableLRUs.add(lru);
        }
      });
    }
  });
  // remove LRUs if feature gate not enabled
  if (!bc03Enabled) {
    availableLRUs.delete('bc03');
  }
  if (!nextisEnabled) {
    availableLRUs.delete('next_is');
  }
  if (!ex3ncEnabled) {
    availableLRUs.delete('ex3_nc');
  }
  if (!vsmEnabled) {
    availableLRUs.delete('smartmonitor');
  }
  if (!ahEnabled) {
    availableLRUs.delete('ah');
  }

  // return a sorted list of the LRUs
  return Array.from(availableLRUs).sort();
}

// get the LRU units that are enabled ex. from { 0: true, 1: false }) to [0]
function getLRUEnabledUnits(units) {
  return Object.keys(units).filter((k) => units[k]);
}

function parseLrusFromRack(rack) {
  // parse out the lrus from the rack object
  const newLrus = { ...defaultLrus };
  Object.entries(rack.rack.lrus).forEach(([lru, values]) => {
    newLrus[lru] = {
      ...newLrus[lru],
      enabled: values.enabled,
      units: parseUnitsFromRack(lru),
      cpu: parseResourceFromRack(lru, 'cpu', values.resources),
      memory: parseResourceFromRack(lru, 'memory', values.resources),
      persistData: values.persistData.toString(),
      size: values.size,
      diskSize: values.diskSize,
      config: values.config,
      system_type: values.system_type
    };
    // add system_type field to LRU object if lru is fs05
    if (lru === 'fs05') {
      newLrus[lru].system_type = 'eXW';
    }
  });
  return newLrus;
}

NewLRUModal.propTypes = {
  rack: PropTypes.object,
  updateRack: PropTypes.func,
  isOpen: PropTypes.bool,
  closeHandler: PropTypes.func
};

export function NewLRUModal({ rack, updateRack, isOpen, closeHandler }) {
  const addedLRUS = getAddedLRUs(rack.rack.lrus);
  const [currentLRU, setCurrentLRU] = useState(null);
  // used to enable/disable only the add LRU button
  const bc03Enabled = useIsFeatureFlagEnabled('bc03');
  const ex3ncEnabled = useIsFeatureFlagEnabled('ex3_nc');
  const nextisEnabled = useIsFeatureFlagEnabled('next_is');
  const vsmEnabled = useIsFeatureFlagEnabled('vsm');
  const ahEnabled = useIsFeatureFlagEnabled('ah');
  const lrus = parseLrusFromRack(rack);
  const availableLRUs = getAvailableLRUs(
    lrus,
    addedLRUS,
    bc03Enabled,
    ex3ncEnabled,
    nextisEnabled,
    vsmEnabled,
    ahEnabled
  );

  // updates the default lru values for a particular lru object depending on certain criteria
  const overrideDefaultLRUValues = (lruName, lru) => {
    // updates the fs05 to eXCs system type if gcs standalone configuration
    if (lruName === 'fs05' && addedLRUS.includes('bc03')) {
      lru.system_type = 'eXCs';
      clearUnits(lru);
      lru.units['8'] = true;
    }

    // updates the bc03 to integrated system type if ah is involved
    if (lruName === 'bc03') {
      if (addedLRUS.includes('ah')) {
        lru.system_type = 'integrated';
      } else {
        lru.system_type = 'standalone';
      }
    }
  };

  // will handle when an LRU is selected from the dropdown
  const handleSelectLRUChange = (event) => {
    const lruName = event.target.value;
    const lru = { ...lrus[lruName] };

    overrideDefaultLRUValues(lruName, lru);
    setCurrentLRU(lru);
  };

  const setLRUPersist = (event) => {
    // update persistence for LRU
    const s = { ...currentLRU };

    s.persistData = event.target.value;
    setCurrentLRU(s);
  };

  const addLRU = () => {
    const rackCopy = { ...rack };
    const newRackUpdate = {
      persistData: currentLRU.persistData,
      enabled: true,
      units: getLRUEnabledUnits(currentLRU.units),
      size: currentLRU.size,
      diskSize: currentLRU.diskSize,
      resources: {
        limits: {
          cpu: `${currentLRU.cpu}m`,
          memory: `${currentLRU.memory}M`
        }
      },
      config: currentLRU.config
    };
    if ('system_type' in currentLRU && currentLRU.system_type !== '') {
      newRackUpdate.system_type = currentLRU.system_type;
    }
    rackCopy.rack.lrus[currentLRU.value] = newRackUpdate;
    updateRack(rackCopy);

    // clear the current lru after adding
    setCurrentLRU(null);
    closeHandler();
  };

  return (
    <Modal
      open={isOpen}
      sx={{ outline: 'none' }}
      aria-labelledby="add-new-lru"
      aria-describedby="modal to add new lru"
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={isOpen}>
        <Grid direction="row" sx={style} container>
          <Grid item xs={12} md={1} lg={3} onClick={closeHandler} />
          <Grid item xs={12} md={10} lg={6}>
            <Card>
              <CardHeader sx={{ padding: 2 }} title={NEW_LRU_MODAL_TITLE} />
              <Divider />
              <CardContent sx={{ paddingTop: 1, maxHeight: '70vh', overflow: 'auto' }}>
                <Grid container spacing={2} alignItems="flex-start">
                  <Grid container spacing={2} item xs={12}>
                    <Grid item mt={2} xs={12}>
                      <FormControl>
                        <InputLabel id="lru-select-label">{LRU_SELECT_LABEL}</InputLabel>
                        <Select
                          labelId="lru-select-label"
                          label={LRU_SELECT_LABEL}
                          data-testid={LRU_SELECT_TEST_ID}
                          value={currentLRU?.value}
                          onChange={handleSelectLRUChange}
                        >
                          {availableLRUs.length === 0 ? (
                            <MenuItem disabled value="">
                              No Valid LRUs to Add
                            </MenuItem>
                          ) : (
                            availableLRUs.map((lru) => (
                              <MenuItem key={lru} value={lru}>
                                {lrus[lru].label}
                                {lrus[lru].beta && <BetaChip sx={{ ml: 1 }} />}
                              </MenuItem>
                            ))
                          )}
                        </Select>
                        <FormHelperText>
                          The type/subtype of LRU you want to add to your rack
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                  {availableLRUs.length > 0 && currentLRU ? (
                    <>
                      <Grid container item spacing={2} xs={12}>
                        <LRUOptions
                          lrus={lrus}
                          currentLRU={currentLRU}
                          updateCurrentLRU={setCurrentLRU}
                        />
                      </Grid>
                      <Grid container item spacing={2} xs={12}>
                        <LRUSize updateCurrentLRU={setCurrentLRU} currentLRU={currentLRU} />
                      </Grid>
                      <Grid container item spacing={2} sm={12}>
                        <Grid item xs={12}>
                          <Accordion>
                            <AccordionSummary
                              sx={{ paddingLeft: 0 }}
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                            >
                              <Typography variant="h6">Advanced Settings</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid item xs={12}>
                                <PersistData
                                  currentLRU={currentLRU}
                                  setLRUPersist={setLRUPersist}
                                />
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </CardContent>
              <Divider />
              <Box sx={{ padding: 2, textAlign: 'right' }}>
                <Button
                  sx={{ marginLeft: 1 }}
                  color="secondary"
                  variant="outlined"
                  onClick={closeHandler}
                >
                  Cancel
                </Button>
                <Button
                  data-testid={NEW_LRU_BUTTON_TEST_ID}
                  disabled={isAddLRUDisabled(currentLRU, addedLRUS)}
                  sx={{ marginLeft: 1 }}
                  color="primary"
                  variant="contained"
                  onClick={addLRU}
                >
                  Add LRU
                </Button>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} med={1} lg={3} onClick={closeHandler} />
        </Grid>
      </Fade>
    </Modal>
  );
}
