import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Mikrotik } from './Mikrotik';
import Connectivity from './Connectivity';
import { useIsFeatureFlagEnabled } from '../../../utils/FeatureFlags';

Networking.propTypes = {
  updateRack: PropTypes.func,
  rack: PropTypes.object,
  setNextButtonDisabled: PropTypes.func
};

export default function Networking({ rack, updateRack, setNextButtonDisabled }) {
  const mikrotikEnabled = useIsFeatureFlagEnabled('mikrotik');

  useEffect(() => {
    validateInputs();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const validateInputs = () => {
    setNextButtonDisabled(false);
  };

  return (
    <Grid container ml={2} spacing={2} mt={1} mb={2} item xs={12}>
      {mikrotikEnabled && (
        <Grid item sm={12}>
          <Mikrotik
            rack={rack}
            updateRack={updateRack}
            setNextButtonDisabled={setNextButtonDisabled}
          />
        </Grid>
      )}
      <Grid item sm={12}>
        <Connectivity
          rack={rack}
          updateRack={updateRack}
          setNextButtonDisabled={setNextButtonDisabled}
        />
      </Grid>
    </Grid>
  );
}
